import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { SessionState } from '../../../shared/store/states/session.state';
import { Observable } from 'rxjs';
import { SessionStorageResponse } from '../../../shared/models/session-storage-response';

@Directive({
  selector: '[appHasRole]',
})
export class HasRoleDirective {
  @Select(SessionState.getSession) session$: Observable<SessionStorageResponse>;
  private currentUserRoles;
  private roles = [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appHasRole(roles: string[]) {
    this.roles = roles;
    this.updateView();
  }

  ngOnInit(): void {
    this.session$.pipe().subscribe(_ => {
      this.currentUserRoles = _.userRoles;
      this.updateView();
    });
  }

  private updateView(): void {
    if (this.checkRoles()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkRoles(): boolean {
    let hasPermission = false;
    if (this.currentUserRoles)
      for (const checkRole of this.roles) {
        const roleFound = this.currentUserRoles.find(
          role => role.toUpperCase() === checkRole.toUpperCase()
        );

        if (roleFound) hasPermission = true;
      }
    return hasPermission;
  }
}
