import { Injectable, NgZone } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SnackBarData, SnackBarTypes, SnackComponent } from './snack.component';

@Injectable({
  providedIn: 'root',
})
export class SnackService {
  currentSnackData: SnackBarData;

  constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

  /**
   * It opens a snackbar with the title 'Success' and the message 'Successfully completed!'
   * @param {string} [title] - The title of the snackbar.
   * @param {string} [message] - The message to be displayed in the snackbar.
   * @param {number} [duration] - The duration of the snackbar in milliseconds.
   */
  openSuccess(title?: string, message?: string, duration?: number): void {
    this.openSnack(
      {
        title: title ?? 'Success',
        message: message ?? 'Successfully completed!',
        type: SnackBarTypes.SUCCESS,
      },
      duration ?? 1500
    );
  }

  /**
   * It opens a snackbar with the title "Error" and the message "Error happened!"
   * @param {string} [title] - The title of the snackbar.
   * @param {string} [message] - The message to be displayed in the snackbar.
   * @param {number} [duration] - The duration of the snackbar in milliseconds.
   */
  openError(title?: string, message?: string, duration?: number): void {
    this.openSnack(
      {
        title: title ?? 'Error',
        message: message ?? 'Error happened!',
        type: SnackBarTypes.ERROR,
      },
      duration ?? 1500
    );
  }

  openSnack(snackData: SnackBarData, duration?: number): void {
    this.zone.run(() => {
      const config = new MatSnackBarConfig();
      config.panelClass = ['custom-snackbar'];
      this.currentSnackData = snackData;
      config.duration = duration ?? 3000;

      this.snackBar.openFromComponent(SnackComponent, config);
    });
  }
}
