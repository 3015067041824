import { Token, User } from '../../models/user';

// export class SetToken {
//   static readonly type = '[AUTH] Set';
//
//   constructor(public token: string) {
//   }
// }

export class LogoutUser {
  static readonly type = '[AUTH] Logout User';
}

export class SetUser {
  static readonly type = '[AUTH] Set User';

  constructor(public user: User) {}
}

export class SetToken {
  static readonly type = '[AUTH] Set Token';

  constructor(public token: Token) {}
}

export class RefreshAccessToken {
  static readonly type = '[AUTH] Refresh Access Token';

  constructor(public token: Token) {}
}
//
// export class RemovePermissions {
//   static readonly type = '[AUTH] Remove All Permissions';
// }
//
// export class AddPermissions {
//   static readonly type = '[AUTH] Add Permissions';
// }
