import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: Pusher;
  companyChannel: Channel;
  notificationsChannel: Channel;
  generatedDocumentChannel: Channel;
  setupFirstChannel: Channel;
  demoChannel: Channel;
  ridePackageHistoryChannel: Channel;

  constructor() {
    this.pusher = new Pusher('112058ff5a507ccee9f7', {
      cluster: 'eu',
    });

    this.demoChannel = this.pusher.subscribe('asd');
    this.setupFirstChannel = this.pusher.subscribe('setupFirst');
    this.companyChannel = this.pusher.subscribe('company');
    this.ridePackageHistoryChannel = this.pusher.subscribe('historyEvent');
  }

  initNotificationChannel(id: number): void {
    this.notificationsChannel = this.pusher.subscribe(`user-${id}`);
  }

  // initRidePackageHistoryChannel(id: number): void {
  //   // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  //   this.ridePackageHistoryChannel = this.pusher.subscribe('historyEvent-' + id);
  // }

  initGeneratedDocumentChannel(id: number): void {
    this.generatedDocumentChannel = this.pusher.subscribe(
      `generateDocument-${id}`
    );
    console.log('GENERATED DOCUMENT CHANNEL INITED WITH ID:', id);
  }

  subscribeToChannel(channelName: string): Channel {
    return this.pusher.subscribe(channelName);
  }
}
