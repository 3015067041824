import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class UpdateDateHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // this.startLoading();
    if (req.method === 'POST' || req.method === 'PUT') {
      this.shiftDates(req.body);
    }

    return next.handle(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  shiftDates(body: any): void {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = new Date(
          Date.UTC(
            value.getFullYear(),
            value.getMonth(),
            value.getDate(),
            value.getHours(),
            value.getMinutes(),
            value.getSeconds()
          )
        );
      } else if (typeof value === 'object') {
        this.shiftDates(value);
      }
    }
  }
}
