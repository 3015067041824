import { Token, User } from '../../models/user';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  LogoutUser,
  RefreshAccessToken,
  SetToken,
  SetUser,
} from '../actions/auth.actions';

export class AuthStateModel {
  userData: User;
  token: Token;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    userData: {} as User,
    token: {} as Token,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static getUser(state: AuthStateModel): User {
    return state.userData;
  }

  @Action(SetUser)
  setUser(
    { patchState }: StateContext<AuthStateModel>,
    { user }: SetUser
  ): void {
    patchState({
      userData: user,
    });
  }

  @Action(SetToken)
  setToken(
    { patchState }: StateContext<AuthStateModel>,
    { token }: SetToken
  ): void {
    patchState({
      token,
    });
  }

  @Action(RefreshAccessToken)
  refreshAccessToken(
    { getState, setState }: StateContext<AuthStateModel>,
    { token }: SetToken
  ): void {
    // debugger;
    const state = getState();
    state.token = token as unknown as Token;
    setState(state);
    // debugger;
  }

  @Action(LogoutUser)
  // eslint-disable-next-line no-empty-pattern
  logoutUser(
    { patchState }: StateContext<AuthStateModel>,
    {}: LogoutUser
  ): void {
    localStorage.clear();
    sessionStorage.clear();
    patchState({
      userData: {} as User,
      token: {} as Token,
    });
  }
}
