import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackService } from '../components/snack/snack.service';
import { SnackBarTypes } from '../components/snack/snack.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private snack: SnackService) {}

  /**
   * HANDLES HTTP ERRORS
   * @param error The Http Error Response
   */
  public onError(error: HttpErrorResponse): void {
    console.log('onError', error);
    switch (error.status) {
      case 400:
        // 400 validation error
        return this.handle400(error);
      case 406:
        // 406 for custom errors
        return this.handle406(error);
      default:
        this.snack.openError(undefined, undefined);
        break;
    }
  }

  private handle400(error: HttpErrorResponse): void {
    if (error.error.jsonMessage) {
      // for each key in jsonMessage, show a toast
      // eslint-disable-next-line
      Object.keys(error.error.jsonMessage).forEach((key, index) => {
        if (index !== 0)
          setTimeout(() => {
            this.snack.openSnack(
              {
                title: 'Error',
                message: `${key} ${error.error.jsonMessage[key]}`,
                type: SnackBarTypes.ERROR,
              },
              4000
            );
          }, 4500);
        else
          this.snack.openSnack(
            {
              title: 'Error',
              message: `${key} ${error.error.jsonMessage[key]}`,
              type: SnackBarTypes.ERROR,
            },
            4000
          );
        console.log(error.error.jsonMessage[key]);
      });
    }
  }
  private handle406(error: HttpErrorResponse): void {
    if (error.error.originalMessage) {
      this.snack.openSnack(
        {
          title: 'Error',
          message: error.error.originalMessage,
          type: SnackBarTypes.ERROR,
        },
        4000
      );
    }
  }
}
