import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public auth: AuthService,
    private router: Router,
    private readonly injector: Injector
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // debugger;
    if (this.auth.token && localStorage.getItem('randomKey')) {
      // debugger;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      request = this.addToken(request, this.auth.token);
    }

    // debugger;
    if (request.url.includes('login') || request.url.includes('register')) {
      return next.handle(request);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // debugger;
    let lang: string;
    try {
      const translateService = this.injector.get(TranslateService);
      lang = translateService?.currentLang ?? 'asd';
      // log using translate service
    } catch {
      lang = 'en';
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'X-Request-ID': localStorage.getItem('randomKey'),
        'Accept-Language': lang ?? 'en',
      },
    });
  }

  // eslint-disable-next-line
  handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return from(this.auth.refreshAccessToken()).pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return next.handle(this.addToken(request, token.access_token));
        })
      );
      // this.auth.refreshAccessToken().then((token: Token) => {
      //   this.isRefreshing = false;
      //   this.refreshTokenSubject.next(token.access_token);
      //   return next.handle(this.addToken(request, token.access_token));
      // });
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }
}
