import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoginData } from '../models/login-data';
import { Environment } from '../../../environments/environment';
import { first, take } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { Token, User } from '../models/user';
import { LogoutUser, SetToken } from '../store/actions/auth.actions';
import { AuthState } from '../store/states/auth.state';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DeleteSession } from '../store/actions/session.actions';
import { ErrorService } from './error.service';
import * as UAParser from 'ua-parser-js';
import { SnackService } from '../components/snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Select(AuthState) auth$: Observable<any>;
  auth: any;
  private env = new Environment();

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private error: ErrorService,
    private snack: SnackService
  ) {
    // --
    this.auth$.subscribe(_ => {
      // debugger;
      this.auth = _;
    });
  }

  get token(): string {
    // debugger;
    // console.log('Token from authService: ', this.auth.token?.access_token);
    return this.auth.token?.access_token;
  }

  /**
   * LOGS IN THE USER
   */
  login(loginData: LoginData): Promise<any> {
    loginData.sessionDetails = new UAParser().getResult();

    return new Promise<any>((resolve, reject) => {
      this.http
        .post(`${this.env.api}login`, loginData)
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.auth = res;
            console.log(res);
            if (res.twoFaEnabled) {
              resolve(res);
            } else {
              const token: Token = {
                access_token: res.access_token,
                token_type: res.token_type,
                expires_in: res.expires_in,
              };
              this.store.dispatch(new SetToken(token));
              this.createRandomKey();
              resolve(res);
            }
            // this.store.dispatch(new SetUser(res.userData));
          },
          (error: HttpErrorResponse) => {
            reject(error);
          }
        );
    });
  }

  /**
   * LOGS IN THE USER WITH 2FA CODE
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  twoFactorLogin(twoFaToken: any, twoFAKey: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post(`${this.env.api}twoFactAuthByToken`, { twoFaToken, twoFAKey })
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.auth = res;
            const token: Token = {
              access_token: res.access_token,
              token_type: res.token_type,
              expires_in: res.expires_in,
            };
            this.store.dispatch(new SetToken(token));
            this.createRandomKey();
            resolve(res);
            // this.store.dispatch(new SetUser(res.userData));
          },
          error => {
            this.error.onError(error);
            reject(Error('Could not login'));
          }
        );
    });
  }

  /**
   * REGISTERS IN THE USER
   */
  register(loginData: LoginData): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .post(`${this.env.api}register`, loginData)
        .pipe(first())
        .subscribe(
          _ => {
            // eslint-disable-next-line no-prototype-builtins
            if (_.hasOwnProperty('originalMessage') !== undefined) {
              this.snack.openSuccess('successful', _['originalMessage']);
            }
            this.snack.openSuccess('successful', 'successful');
            resolve(true);
          },
          () => {
            reject();
          }
        );
    });
  }

  /**
   * DELETE USER
   */
  deleteUser(id: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .delete(`${this.env.api}deleteUser/${id}`)
        .pipe(first())
        .subscribe(
          _ => {
            // eslint-disable-next-line no-prototype-builtins
            if (_.hasOwnProperty('originalMessage') !== undefined) {
              this.snack.openSuccess('successful', _['originalMessage']);
            }
            this.snack.openSuccess('successful', 'successful');
            resolve(true);
          },
          () => {
            reject();
          }
        );
    });
  }

  /**
   * UPDATE USER
   */
  updateUser(user: User): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .put(`${this.env.api}user/${user.id}`, user)
        .pipe(first())
        .subscribe(
          _ => {
            // eslint-disable-next-line no-prototype-builtins
            if (_.hasOwnProperty('originalMessage') !== undefined) {
              this.snack.openSuccess('successful', _['originalMessage']);
            }
            this.snack.openSuccess('successful', 'successful');
            resolve(true);
          },
          () => {
            reject();
          }
        );
    });
  }

  /**
   * REFRESHES THE ACCESS TOKEN
   */
  refreshAccessToken(): Promise<Token> {
    // debugger;
    return new Promise((resolve, reject) => {
      console.log('itt mó');
      this.http
        .get(`${this.env.api}refresh`)
        .pipe(take(1))
        .subscribe(
          (res: Token) => {
            // debugger;
            // console.log('REHRESH TOKEN RESPONSE');
            // console.table(res);
            this.store.dispatch(new SetToken(res));
            resolve(res);
          },
          () => {
            reject();
          }
        );
    });
  }

  /**
   * SEND RESET PASSWORD EMAIL
   */
  resetPassword(email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.env.api}resetPassword/${email}`)
        .pipe(take(1))
        .subscribe(
          (res: string) => {
            resolve(res);
          },
          () => {
            reject();
          }
        );
    });
  }

  isLoggedIn(): boolean {
    if (this.auth?.token) {
      return !!this.auth?.token?.access_token;
    }
    return false;
  }

  logOut(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.store.dispatch(new LogoutUser());
    this.store.dispatch(new DeleteSession());
    void this.router.navigate(['/auth/login'], { replaceUrl: true });
  }

  createRandomKey(): void {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result = ' ';
    const length = 64;
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    console.log(result);

    localStorage.setItem('randomKey', result);
  }
}
