import { NgModule } from '@angular/core';
import { ScrollbarThemeDirective } from './scrollbar.directive';
import { CommonModule } from '@angular/common';
import { TruncateTooltipDirective } from './truncate-tooltip.directice';
import { HasPermissionDirective } from './has-permission.directive';
import { HasRoleDirective } from './has-role.directive';

@NgModule({
  declarations: [
    ScrollbarThemeDirective,
    TruncateTooltipDirective,
    HasPermissionDirective,
    HasRoleDirective,
  ],
  imports: [CommonModule],
  exports: [
    ScrollbarThemeDirective,
    TruncateTooltipDirective,
    HasPermissionDirective,
    HasRoleDirective,
  ],
})
export class DirectivesModule {}
