import { Component } from '@angular/core';
import { SnackService } from './snack.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack',
  templateUrl: './snack.component.html',
  styleUrls: ['./snack.component.scss'],
})
export class SnackComponent {
  snackData: SnackBarData;
  SnackBarTypes = SnackBarTypes;

  constructor(
    public snackService: SnackService,
    private matSnackBar: MatSnackBar
  ) {
    this.snackData = this.snackService.currentSnackData;
  }

  close(): void {
    this.matSnackBar.dismiss();
  }
}

export interface SnackBarData {
  type: SnackBarTypes;
  title: string;
  message: string;
}

export enum SnackBarTypes {
  SUCCESS,
  WARNING,
  ERROR,
}
