import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxsWrapperModule } from './shared/modules/ngxs.wrapper.module.';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AvatarModule } from 'ngx-avatars';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { SnackModule } from './shared/components/snack/snack.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DirectivesModule } from './shared/directives/directives.module';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MyDateAdapter } from './shared/date-adapters/my-date-adapter';
import {
  NgxMatDateAdapter,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { NgxMyDateAdapter } from './shared/date-adapters/ngx-my-date-adapter';
import { TranslatorService } from './shared/services/translator.service';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { UpdateDateHttpInterceptor } from './shared/interceptors/date.interceptor';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor.service';
import { QuillModule } from 'ngx-quill';
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from 'ngx-swiper-wrapper';
import { InitializerService } from './shared/services/initializer.service';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

const swiperConfig = [
  {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG,
  },
];

/**
 * Note we need a separate function as it's required
 * by the AOT compiler.
 */
export function playerFactory(): LottiePlayer {
  return player;
}

/**
 * AoT requires an exported function for factories
 * @param http
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

/**
 * HTTP Loader for Translations
 * @param http
 */
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UpdateDateHttpInterceptor,
    multi: true,
  },
];

const dateAdapters = [
  {
    provide: DateAdapter,
    useClass: MyDateAdapter,
  },
  {
    provide: NgxMatDateAdapter,
    useClass: NgxMyDateAdapter,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    NgxsWrapperModule,
    HttpClientModule,
    MatNativeDateModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    AvatarModule.forRoot({
      colors: [
        '#7068B4',
        '#E5AF55',
        '#179884',
        '#EC524B',
        '#EC8B46',
        '#3A69A6',
      ],
    }),
    // GooglePlaceModule,
    // AgmCoreModule.forRoot({
    //     apiKey: environment.googleMapsApiKey,
    //     libraries: ['places'],
    // }),
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    SnackModule,
    SwiperModule,
    DirectivesModule,
    NgxMatNativeDateModule,
    MatSelectCountryModule.forRoot('en'),
    QuillModule.forRoot(),
  ],
  providers: [
    ...interceptors,
    ...dateAdapters,
    ...swiperConfig,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: InitializerService, ps: NgxPermissionsService) =>
        function () {
          return ds
            .init()
            .then(data => {
              // debugger;
              console.log('permissions loaded');
              return ps.loadPermissions(data);
            })
            .catch(() => {
              return null;
            });
        },
      deps: [InitializerService, NgxPermissionsService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'hu-HU' },
    LocalNotifications,
    TranslatorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
