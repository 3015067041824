import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollbarTheme]',
})
export class ScrollbarThemeDirective {
  constructor(el: ElementRef) {
    const stylesheet = `
      ::-webkit-scrollbar {
      width: 0 !important;
      }
      ::-webkit-scrollbar-track {
      background: none;
      }
      ::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: none;
      border: 0;
      }
      ::-webkit-scrollbar-thumb:hover {
      }
    `;

    try {
      const styleElement =
        el?.nativeElement?.shadowRoot?.querySelector('style');

      if (styleElement) {
        styleElement.append(stylesheet);
      } else {
        const barStyle = document.createElement('style');
        barStyle.append(stylesheet);
        el.nativeElement.shadowRoot.appendChild(barStyle);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
