import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { SessionState } from '../../../shared/store/states/session.state';
import { Observable } from 'rxjs';
import { SessionStorageResponse } from '../../../shared/models/session-storage-response';

@Directive({
  selector: '[appHasPermission]',
})
export class HasPermissionDirective {
  @Select(SessionState.getSession) session$: Observable<SessionStorageResponse>;
  private currentUserPermissions;
  private permissions = [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appHasPermission(permission: string[]) {
    this.permissions = permission;
    this.updateView();
  }

  ngOnInit(): void {
    this.session$.pipe().subscribe(_ => {
      this.currentUserPermissions = _.userPermissions;
      console.log(_);
      this.updateView();
    });
  }

  private updateView(): void {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasPermission = false;
    if (this.currentUserPermissions)
      for (const checkPermission of this.permissions) {
        const permissionFound = this.currentUserPermissions.find(
          role => role.toUpperCase() === checkPermission.toUpperCase()
        );

        if (permissionFound) hasPermission = true;
      }
    return hasPermission;
  }
}
