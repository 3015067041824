import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  SellerData,
  SessionStorageResponse,
  UserData,
} from '../../models/session-storage-response';
import { DeleteSession, SetSession } from '../actions/session.actions';

export class SessionStateModel {
  session: SessionStorageResponse;
}

@State<SessionStateModel>({
  name: 'session',
  defaults: {
    session: {} as SessionStorageResponse,
  },
})
@Injectable()
export class SessionState {
  @Selector()
  static getUser(state: SessionStateModel): UserData {
    return state.session.userData;
  }
  @Selector()
  static getSeller(state: SessionStateModel): SellerData {
    return state.session.sellerData;
  }

  @Selector()
  static getSession(state: SessionStateModel): SessionStorageResponse {
    return state.session;
  }

  @Action(SetSession)
  setSession(
    { getState, setState }: StateContext<SessionStateModel>,
    session: { session: SessionStorageResponse }
  ): void {
    // debugger;
    const s = getState();

    // debugger;
    setState({
      ...s,
      session: session.session,
    });
    // patchState({
    //   session: session.session,
    // });
  }

  @Action(DeleteSession)
  deleteSession({ patchState }: StateContext<SessionStateModel>): void {
    patchState({
      session: {} as SessionStorageResponse,
    });
    localStorage.clear();
    sessionStorage.clear();
  }

  // @Action(RefreshAccessToken)
  // refreshAccessToken({ getState, setState }: StateContext<SessionStateModel>, { token }: SetToken): void {
  //   // debugger;
  //   const state = getState();
  //   state.token = token as unknown as Token;
  //   setState(state);
  //   // debugger;
  // }
  //
  // @Action(LogoutUser)
  // logoutUser({ patchState }: StateContext<SessionStateModel>, {}: LogoutUser): void {
  //   localStorage.clear();
  //   patchState({
  //     userData: {} as User,
  //     token: {} as Token,
  //   });
  // }
}
