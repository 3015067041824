import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  setItem(key: string, value: unknown): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  deleteItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  isSessionSet(): boolean {
    return !JSON.parse(sessionStorage.getItem('isSet'));
  }
}
